export const markers = [
  {
    title: "Hotel Amet minim dolore",
    address: "Lorem commodo cillum",
    lat: 41.900239325405614,
    lng: 12.414144846201657,
    photo: "https://www.aroundweb.it/react/maps/hotel1.jpg",
    description:
      "Magna elit magna aliqua sed elit tempor lorem minim minim commodo aliquip magna tempor fugiat.",
  },
  {
    title: "Hotel Lorem cillum adipiscing.",
    address: "Tempor minim esse",
    lat: 41.93588013298424,
    lng:  12.4150602864065,
    photo: "https://www.aroundweb.it/react/maps/hotel2.jpg",
    description:
      "Adipiscing commodo aliqua adipiscing incididunt aliquip labore nostrud consequat esse veniam enim quis dolore voluptate.",
  },
  {
    title: "Hotel Enim ut quis",
    address: "Aliquip esse cillum",
    lat: 41.9326875,
    lng: 12.4813655,
    photo: "https://www.aroundweb.it/react/maps/hotel3.jpg",
    description:
      "Fugiat labore amet nostrud dolore amet do voluptate duis voluptate enim tempor cillum nostrud duis.",
  },
  {
    title: "Hotel Sed laboris elit.",
    address: "Cillum nostrud duis",
    lat: 41.938651111914936,
    lng: 12.578224540510913,
    photo: "https://www.aroundweb.it/react/maps/hotel4.jpg",
    description:
      "Dolor veniam enim enim nostrud duis consequat magna labore eiusmod quis aliquip labore aliquip voluptate.",
  },
  {
    title: "Hotel Aliquip minim labore",
    address: "Magna do exercitation",
    lat: 41.867359073857116,
    lng: 12.569298149765059,
    photo: "https://www.aroundweb.it/react/maps/hotel5.jpg",
    description:
      "Incididunt esse et consectetur eiusmod aliquip amet dolor labore labore lorem sed aliqua cillum fugiat.",
  },
  {
    title: "Hotel Tempor enim do",
    address: "Et minim sed",
    lat: 41.847413729830095,
    lng: 12.477287649424865,
    photo: "https://www.aroundweb.it/react/maps/hotel6.jpg",
    description:
      "Exercitation adipiscing lorem amet commodo cillum lorem adipiscing dolore voluptate tempor aliquip cillum laboris enim.",
  },
];
